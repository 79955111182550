// Customizations to default Bulma variables

// #### ~bulma/sass/utilities/initial-variables.sass
// Colors

$orange: $color-orange-200;
$green: hsl(141, 53%, 53%);
$blue: $color-blue-300;
$purple: $color-purple-200;

// Typography

$family-sans-serif:
  'Roboto',
  BlinkMacSystemFont,
  -apple-system,
  'Segoe UI',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  'Helvetica',
  'Arial',
  sans-serif;

$size-1: 3.5rem;
$size-2: 3rem;
$size-3: 2.5rem;
$size-4: 2rem;
$size-5: 1.6rem;
$size-6: 1.25rem;
$size-7: 1.1rem;
$size-8: 0.5rem;

// Miscellaneous

$radius-rounded: 290486px;

@import '~bulma/sass/utilities/functions';

// #### ~bulma/sass/utilities/derived-variables.sass
$primary: $blue;

$info: $purple;
$success: $green;
$warning: $orange;
$danger: $color-red-400;
$stable: $color-grey-200;

// Invert colors

$primary-light: $color-blue-000;
$primary-dark: $color-blue-800;
$info-light: $color-purple-000;
$info-dark: $color-purple-500;
$success-light: $color-green-100;
$success-dark: $color-green-600;
$warning-light: $color-orange-000;
$warning-dark: $color-yellow-800;
$danger-light: $color-red-100;
$danger-dark: $color-red-600;

$custom-colors: (
  'stable': $stable,
);

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8;

// Controls

$control-height: 2.5em;
$control-line-height: 1.3;
$input-height: $control-height;
$input-placeholder-color: $color-grey-700;

// Buttons

$button-padding-horizontal: 0.75em;
$button-text-decoration: none;

// Dropdown

$dropdown-content-z: $above-composer-layer;
$dropdown-item-hover-background-color: $hover-color;

// Modal

$modal-z: $above-composer-layer;
$modal-background-background-color: rgba($color-grey-800, 0.4);
$modal-card-body-padding: 0;
$modal-card-head-padding: 1.2rem 1.5rem;
$modal-card-head-background-color: $color-white;
$modal-card-foot-background-color: $color-white;
$modal-card-title-size: $size-5;
$modal-card-title-line-height: 1.35;

// Card

$card-shadow: $box-shadow-default;
$card-radius: $border-radius-2;
$card-header-padding: $sp-3 $sp-4;
$card-content-padding: $sp-3 $sp-4;
$card-footer-padding: $sp-3 $sp-4;

// Table

$table-row-active-background-color: $color-blue-000;
$table-row-active-color: findColorInvert($color-blue-000);

// Progress

$progress-border-radius: $radius-rounded;
