.button {
  min-width: 2.5em;

  &.is-encounterSectionHeader {
    @media (max-width: $tablet) {
      min-width: 4.5rem;
    }

    min-width: 7rem;
  }

  .icon {
    flex: 0 0 auto;

    img,
    svg {
      width: 1.25em;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.control .icon {
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  svg {
    width: 1.25em;
    height: auto;
    max-height: 80%;
  }
}

.modal.is-composer-accessible {
  z-index: $below-composer-layer;
}

.modal-card-body {
  &:last-child {
    border-bottom-left-radius: $modal-card-foot-radius;
    border-bottom-right-radius: $modal-card-foot-radius;
  }
}

.modal-card-foot {
  justify-content: space-between;
}

.modal-card {
  max-width: 100%;
}

.modal-card--takeover {
  width: 100%;
  height: 100%;
  max-height: 100%;

  .modal-card-head {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .modal-card-body:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .modal-card-foot {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include from-breakpoint($mobile) {
    width: 40rem;
    height: 60rem;
    max-height: calc(100vh - 40px);

    .modal-card-head {
      border-top-left-radius: $modal-card-head-radius;
      border-top-right-radius: $modal-card-head-radius;
    }

    .modal-card-body:last-child {
      border-bottom-left-radius: $modal-card-foot-radius;
      border-bottom-right-radius: $modal-card-foot-radius;
    }

    .modal-card-foot {
      border-bottom-left-radius: $modal-card-foot-radius;
      border-bottom-right-radius: $modal-card-foot-radius;
    }
  }
}

.modal-card-title {
  flex-shrink: 1;
}

.modal-card-closeButton {
  color: $color-grey-600;
}

.dropdown-content {
  padding-bottom: 0;
}

.dropdown-item {
  font-size: 1.25rem;
  padding: 0.6rem 1rem;
  border-radius: 0;

  &:not(:last-child) {
    border-bottom: $border-default;
  }
}

a.dropdown-item,
button.dropdown-item {
  padding-right: 1rem;
}

button.dropdown-item {
  background: none;
  border: none;
  cursor: pointer;

  .icon {
    padding-left: $sp-2;
  }
}

.table tr.is-selected {
  td,
  th {
    border-color: $grey-lighter;
  }
}

.select:not(.is-multiple, .is-loading)::after {
  top: 55%;
}

.dropdown-divider {
  margin: 0;
}

.radio,
.checkbox {
  input {
    position: relative;
    top: 0.125em;
  }
}

.checkbox {
  input[type='checkbox'] {
    margin-right: $sp-2;
  }
}

.card {
  border: $border-default;
}
