$layer-0: 0;
$layer-1: 100;
$layer-2: $layer-1 + 1;
$layer-3: $layer-2 + 1;
$layer-4: $layer-3 + 1;
$layer-5: $layer-4 + 1;
$tooltip-layer: $layer-5 + 1;
$below-composer-layer: $tooltip-layer + 1;
$composer-layer: $below-composer-layer + 1;
$above-composer-layer: $composer-layer + 1;
